import React, { Component, Fragment } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import Modal from 'react-modal';
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiChevronUp,
  FiCheck,
} from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";

import about from "../assets/images/about/about-4.png";
import about2 from "../assets/images/about/about-3.png";

Modal.setAppElement('#root');

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--32",
    category: "",
    title: "Sanatın Buluşma Noktası.",
    description:
      "Sahne sanatlarının büyüsünü ve stand-up komedinin en iyi örneklerini sunan lider tiyatro organizasyonu.",
    buttonText: "Bize Ulaşın",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--31",
    category: "",
    title: "Romantik Tiyatro.",
    description:
      "Aşkın ve tutkunun izini sürdüğümüz oyunlarımızla duygusal bir yolculuğa çıkın.",
    buttonText: "Bize Ulaşın",
    buttonLink: "/contact",
  },
];

const ServiceListOne = [
  {
    image: "01",
    title: "Yaratıcı Gelişim",
    description: "Sanatsal yaratıcılığınızı sahneye taşıma konusunda uzmanız.",
  },
  {
    image: "02",
    title: "İş Danışmanlığı",
    description: "Tiyatro ve etkinlik organizasyonlarınız için profesyonel iş danışmanlığı sağlıyoruz.",
  },
  {
    image: "03",
    title: "İşletme Gelişimi",
    description: "Tiyatro işletmenizi bir sonraki seviyeye taşıyacak stratejiler geliştiriyoruz.",
  },
];

const starndardService = [
  {
    image: "01",
    title: "Thinking Development",
    description: "I throw myself down among the tall grass by the stream",
  },
  {
    image: "02",
    title: "Business Consulting",
    description: "I throw myself down among the tall grass by the stream",
  },
  {
    image: "03",
    title: "Biseness Development",
    description: "I throw myself down among the tall grass by the stream",
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      showPopup: true,//
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);//
    this.closePopup = this.closePopup.bind(this); //
  }

  componentDidMount() {
    // Check if the pop-up has already been closed in this session
    const popupClosed = sessionStorage.getItem('popupClosed');
    if (popupClosed) {
      this.setState({ showPopup: false });
    }
  }

  closeModal() {
    this.setState({ isOpen: false });
  }
  closePopup() {
    this.setState({ showPopup: false });
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "İş Analitiği Felsefesi",
      "İşinizi Hızlandırın",
      "İş Hakkında Yalanlar ve Gerçekler",
      "İş Üzerine En İyi Anlaşma",
    ];
    var namesItemTwo = [
      "İşin Gerçekten İşe Yaradığının Kanıtı",
      "İşiniz İçin Yapmanız Gerekenler",
      "İşin Gizli Sırrı",
    ];
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1000' // Make sure it's above other content
      },
      overlay: {
        position: 'fixed', // Fixed position to cover the whole screen
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.75)',
        zIndex: 10000 // Use a high value to ensure it's on top
      }
    };
    const PostList = BlogContent.slice(0, 3);

    return (
      <Fragment>
        <Helmet pageTitle="Kastamonu Organizasyon" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}
        {/* Pop-up Modal */}
        {this.state.showPopup && (
          <Modal
          isOpen={this.state.showPopup}
          onRequestClose={this.closePopup}
          style={customStyles}
          contentLabel="Popup Modal"
        >
          <div style={{ position: 'relative' }}>
            <button onClick={this.closePopup} style={{
              position: 'absolute',
              top: '0',
              right: '0',
              background: 'transparent',
              border: 'none',
              fontSize: '1.5rem',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
              &times;
            </button>
            <img src={`${process.env.PUBLIC_URL}/af.jpg`} alt="Popup Poster" style={{
              width: '100%',
              height: 'auto'
            }} />
          </div>
        </Modal>
        )}
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition} `}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title" style={{ color: '#F9004D', textShadow: '2px 2px 4px #000000' }}>{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description" style={{ color: '#F9004D', textShadow: '2px 2px 4px #000000', fontWeight: '600' }}>{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center" href="/service-details">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Service  */}
              <div className="col-lg-3 col-md-6 col-12 mt--30">
                <div className="section-title">
                  <h2 className="title">Hizmetlerimiz</h2>
                  <p>
                    Eşsiz tiyatro deneyimlerimiz, her bir sahne sanatı eserimizde yaşanır.
                  </p>
                  <div className="service-btn">
                    <a className="btn-transparent rn-btn-dark" href="/service">
                      <span className="text">Özel Hizmet</span>
                    </a>
                  </div>
                </div>
              </div>
              {/* End Single Service  */}


            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start Counterup Area */}
        <div
          className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white"
          data-black-overlay="7"
        >
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End Counterup Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img className="w-100" src={about} alt="Hakkımızda Görseller" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Çalışma Sürecimiz</h2>
                    <p>
                      Her sahne sanatı eserimiz, titizlikle hazırlanan bir sanat yolculuğudur.
                    </p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      Nasıl Çalışıyoruz
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Team Area  */}
        <div className="rn-team-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">Ekiplerimiz</h2>
                  <p>
                    Tiyatro sanatının her disiplininde deneyimli, sanata adanmış profesyoneller.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <Team
                column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
                teamStyle=""
                item="4"
              />
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Hakkımızda</h2>
                    <p className="description">
                      Tiyatro sanatının her disiplininde deneyimli, sanata adanmış profesyoneller.
                    </p>
                  </div>
                  <div className="mt--30">
                    <h4>Sanatın Özü.</h4>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <h4>Üretkenliğimiz</h4>
                    <ul className="list-style--1">
                      {namesItemTwo.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src={about2}
                    alt="About Images"
                  />
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="ZOoVOfieAF8"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  <button
                    className="video-popup position-top-center theme-color"
                    onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Brand Area  */}
        <div className="rn-brand-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandOne branstyle="branstyle--2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Pricing Tbale Area  */}
        <div className="rn-pricing-table-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">Yaklaşan Oyunlarımız</h2>
                  <p>
                      Her biri, sahne sanatlarının büyüleyici dünyasına açılan bir kapı olan benzersiz tiyatro oyunlarımız.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">Stand-up Komedi</h4>
                      <div className="pricing">
                        <span className="price">250 ₺</span>
                        <span className="subtitle"> Bilet Başına</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Kahkaha Tufanı
                        </li>
                        <li>
                          <FiCheck /> Mizah Şöleni
                        </li>
                        <li>
                          <FiCheck /> Gülmece Gecesi
                        </li>
                        <li>
                          <FiCheck /> Felekten Kahkahalar
                        </li>
                        <li>
                          <FiCheck /> Komedi
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#pricing">
                        İletişim
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">Romantik Tiyatro</h4>
                      <div className="pricing">
                        <span className="price">250 ₺</span>
                        <span className="subtitle">Bilet Başına</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Aşkın Halleri
                        </li>
                        <li>
                          <FiCheck /> Gönül Yarası
                        </li>
                        <li>
                          <FiCheck /> Bir Yaz Gecesi Rüyası
                        </li>
                        <li>
                          <FiCheck /> Drama
                        </li>
                        <li>
                          <FiCheck /> İçinden Çıkılmaz Duygular
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#pricing">
                        İletişim
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">Klasik Tiyatro</h4>
                      <div className="pricing">
                        <span className="price">250 ₺</span>
                        <span className="subtitle">Bilet Başına</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Hamlet
                        </li>
                        <li>
                          <FiCheck /> Shakespeare
                        </li>
                        <li>
                          <FiCheck /> Othello
                        </li>
                        <li>
                          <FiCheck /> Macbeth
                        </li>
                        <li>
                          <FiCheck /> Beaumont
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#pricing">
                        İletişim
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
            </div>
          </div>
        </div>
        {/* End Pricing Tbale Area  */}

        {/* Start Blog Area */}
        <div className="rn-blog-area pt--120 pb--80 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <h2 className="title">Son Etkinlikler ve Haberler</h2>
                  <p>
                    Tiyatro dünyamızdan son haberler, yaklaşan etkinlikler ve özel röportajlar.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img
                          className="w-100"
                          src={value.images}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Daha Fazla Oku
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    
    );
  
  }
}
export default CorporateBusiness;
